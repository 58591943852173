<template>
 <CRow>
      <CCol col="12">
        <CCard>
          <CCardBody>
             <CRow>
               <CCol col="12">
                 <CRow>
                      <CCol col="12">
                        <h4 class="card-title mb-0 text-center">Cantidad Mensajes Enviados Por Mes</h4>
                        <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
                      </CCol>
                    </CRow>
                    <CChartBar
                        :datasets="defaultDatasets"
                        :labels="this.dataChartMensajesMes['dias']"
                        
                      /><!--dataChartMensajesMes['dias']-->
               </CCol>
             </CRow>
             <br>
             <CRow>
               <CCol col="12">
                   <h4 class="card-title mb-0 text-center">Detalle Cantidad Mensajes Enviados Por Mes</h4>
                    <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
               </CCol>
               <CCol col="12">
                      <table class="table">
                        <tr>
                          <th>Mes</th>
                          <th>Mensajes Enviados</th>
                          <th>Creditos Consumidos</th>
                        </tr>
                        <tr v-for="(item, index) in items">
                          <td>{{item['dia']}}</td>
                          <td>{{creditosConversorMoneda(item['mensajes'])}}</td>
                          <td>{{creditosConversorMoneda(item['credito'])}}</td>
                        </tr>
                        <tr>
                           <td></td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalCelulares)}}</td> </td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalMensajes)}}</td>
                        </tr>
                     </table>
               </CCol>
             </CRow>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
  
</template>

<script>
import { CChartBar } from '@coreui/vue-chartjs'

export default {
  name: 'ChartMensajesMes',
  components: {
    CChartBar
  },
  data () {
    return {
      items: [],
      fields: [
        { key: 'dia',label:'Dia'},
        { key: 'mensajes',label:'Mensajes Enviados',},
        { key: 'credito',label:'Creditos Consumidos'},
      ],
      activePage: 1,
    }
  },
  props:{
    dataChartMensajesMes: Object,
    dataConsultaChart: Object,
  },
  methods: {
    creditosConversorMoneda(value){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      let f =  (formatterDolar.format(value)).replace('$','');
      return (f.replace('.00',''));
    },
  },
  computed: {
    totalCelulares(){
      return this.dataChartMensajesMes['enviados'] != undefined ? this.dataChartMensajesMes['enviados'].reduce((a, b) => parseInt(a) + parseInt(b), 0) : '' ;
    },
    totalMensajes(){
      return this.dataChartMensajesMes['total'] != undefined ? this.dataChartMensajesMes['total'].reduce((a, b) => parseInt(a) + parseInt(b), 0) : '' ;
    },
    defaultDatasets () {
      try {
        let array = this.dataChartMensajesMes['dias']
        for (let index = 0; index < array.length; index++) {
          this.items.push({dia:this.dataChartMensajesMes['dias'][index],mensajes:this.dataChartMensajesMes['enviados'][index],credito:this.dataChartMensajesMes['total'][index]});
          
        }
      } catch (error) {
        
      }
      return [
        {
          label: 'Mensajes Enviados',
          backgroundColor: '#f87979',
          data: this.dataChartMensajesMes['enviados']//[40, 0, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
        }
      ]
    },
  }
}
</script>

<template>
<CContainer>
  <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader>
            <CRow class="justify-content-between">
              <CCol col="3">
                <h3>Estadisticas</h3>
              </CCol>
              <CCol col="4">
                <button class="btn btn-success" @click="printFacture()">Descargar Reporte PDF</button>
                <button class="btn btn-warning" @click="handleClick()">Atras</button>
              </CCol>
            </CRow>
          </CCardHeader>
        </CCard>
      </CCol>
   </CRow>
   <div id="captura-pdf" ref="DownloadComp" >
     
     <CRow>
      <CCol col="12">
        <CCard>
          <CCardBody>
            <CRow class="justify-content-between">
              <CCol col="8">
                <h1 class="text-center">Estadísticas Sms Masivo</h1>
                <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
              </CCol>
              <CCol col="4">
                <div class="c-avatar" style="width:70px;height:70px;">
                  <img
                    :src="obtenerLogo"
                    class="c-avatar-img"
                    :style="fondoLogo"
                  />
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
      <ChartCirculos :dataChartCirculos="dataChartCirculos" :dataConsultaChart="dataConsultaChart" />
      <ChartMensajes :dataChartMensajes="dataChartMensajes" :dataConsultaChart="dataConsultaChart" />
      <ChartMensajesMes :dataChartMensajesMes="dataChartMensajesMes" :dataConsultaChart="dataConsultaChart" />
      <ChartMensajesSemanal :dataChartMensajesSemanal="dataChartMensajesSemanal" :dataConsultaChart="dataConsultaChart" />
      <ChartMensajesCampana :dataChartMensajesCampana="dataChartMensajesCampana" :dataConsultaChart="dataConsultaChart" />
      <ChartMensajesSubcuenta :dataChartMensajesSubcuenta="dataChartMensajesSubcuenta" :dataConsultaChart="dataConsultaChart" />
   </div>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
  </div>
</CContainer>

</template>


<script>
import html2canvas from 'html2canvas/dist/html2canvas.js'
import ChartMensajes from './charts/ChartMensajes.vue'
import ChartMensajesMes from './charts/ChartMensajesMes.vue'
import ChartCirculos from './charts/ChartCirculos.vue'
import ChartMensajesSemanal from './charts/ChartMensajesSemanal.vue'
import ChartMensajesCampana from './charts/ChartMensajesCampana.vue'
import ChartMensajesSubcuenta from './charts/ChartMensajesSubcuenta.vue'
import { jsPDF } from "jspdf"
//import ChartMapa from './charts/ChartMapa.vue'
export default {
  name: 'Estadisticas',
  components: {
    ChartMensajes,
    ChartMensajesMes,
    ChartCirculos,
    ChartMensajesSemanal,
    ChartMensajesCampana,
    ChartMensajesSubcuenta,
  },
  data () {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      mensaje_success:'Grupo Creado Correctamente.',
      dataChartMensajes:{},
      dataChartMensajesMes:{},
      selected:'Dia',
      dataChartCirculos:{},
      dataChartMensajesSemanal:{},
      dataChartMensajesCampana:{},
      dataChartMensajesSubcuenta:{},
    }
  },
  props:{
    dataConsultaChart: Object
  },
  methods: {
     consultaDataCharts(){
          this.$store.dispatch('auth/loading_http','visible');
          this.$http.post('/sms/obtener/chart',this.dataConsultaChart).then(response => {
             let data = response.data;
             this.dataChartMensajes = data['data1'];
             this.dataChartMensajesMes = data['data2'];
             this.dataChartCirculos = data['data4'];
             this.dataChartMensajesSemanal = data['data5'];
             this.dataChartMensajesCampana = data['data6'];
             this.dataChartMensajesSubcuenta = data['data7'];
          }).catch((e)=>{
            this.mostrarNotificacionError ++;
          }).finally(() =>this.$store.dispatch('auth/loading_http','hidden')); 
     },
     printFacture() {
          this.$store.dispatch('auth/loading_http','visible');
          let width_antes = document.getElementById('captura-pdf').offsetWidth;
          let height_antes = document.getElementById('captura-pdf').offsetHeight;
          var element = document.getElementById('captura-pdf');
          if (width_antes < 973) {
              document.getElementById('captura-pdf').style.width = '973px';
              //document.getElementById('captura-pdf').style.height = '5154px';
              document.getElementById('captura-pdf').style.cursor = 'se-resize';
          }
         
          var element = document.getElementById('captura-pdf');
          var width= element.offsetWidth;//element.style.width;
          var height = element.offsetHeight;//element.style.height;
          console.log(width_antes);
          console.log(width);
          var pdf =  width_antes < 973 ?  new jsPDF({orientation: 'portrait',unit:'pt', format:[(1100),(height/4)+height]}) : new jsPDF({orientation: 'portrait',unit:'pt', format:[(width*0.85),(height*0.85)]});
          html2canvas(element).then(canvas => {
              var image = canvas.toDataURL('image/png');
              //console.log(image);
              pdf.addImage(image, 'PNG',2,2);
              pdf.save(`Estadisticas_${this.dataConsultaChart.fechainicio}_${this.dataConsultaChart.fechafin}.pdf`);
              if (width_antes < 973) {
                  document.getElementById('captura-pdf').style.width = width_antes+'px';
                  document.getElementById('captura-pdf').style.height = height_antes+'px';
                  document.getElementById('captura-pdf').style.cursor = 'se-resize';
              }
              this.$store.dispatch('auth/loading_http','hidden');
          });
          /*var element = document.getElementById('captura-pdf');
          html2canvas(element).then(canvas => {
          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 210; 
          var pageHeight = 295;  
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF('p', 'pt','a4',true);//jsPDF('p', 'mm');
          var position = 10; // give some top padding to first page

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position += heightLeft - imgHeight; // top padding for other pages
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save( 'file.pdf');
          });*/

      },
      handleClick: function() {
        this.$root.$emit('clickedSomething',1)
      }
  },
  mounted(){
    setTimeout(() => {
      this.consultaDataCharts();
    }, 100);
  },
  computed: {
    obtenerLogo(){
      return this.$store.state.auth.logo_web;
    },
    fondoLogo(){
      try {
        let url = this.$store.state.auth.logo_web;
        if (url.search("img/avatars") >= 0) {
          return 'background:white;'; 
        }else{
          return ''; 
        }
      } catch (error) {
        return ''; 
      }
    },
  }
}
</script>

<template>
 <CRow>
      <CCol col="12">
        <CCard>
          <CCardBody>
             <CRow>
               <CCol col="12">
                 <CRow>
                      <CCol col="12">
                        <h4 class="card-title mb-0 text-center">Cantidad Mensajes Enviados Por Dia</h4>
                        <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
                      </CCol>
                    </CRow>
                      <CChartLine
                            style="height:300px;margin-top:40px;"
                            :datasets="defaultDatasets"
                            :options="defaultOptions"
                            :labels="dataChartMensajes['dias']"
                          />
               </CCol>
             </CRow>
             <br>
             <CRow>
               <CCol col="12">
                   <h4 class="card-title mb-0 text-center">Detalle Cantidad Mensajes Enviados Por Dia</h4>
                    <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
               </CCol>
               <CCol col="12">
                      <table class="table">
                        <tr>
                          <th>Dia</th>
                          <th>Mensajes Enviados</th>
                          <th>Creditos Consumidos</th>
                        </tr>
                        <tr v-for="(item, index) in items">
                          <td>{{item['dia']}}</td>
                          <td>{{creditosConversorMoneda(item['mensajes'])}}</td>
                          <td>{{creditosConversorMoneda(item['credito'])}}</td>
                        </tr>
                        <tr>
                           <td></td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalCelulares)}}</td> </td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalMensajes)}}</td>
                        </tr>
                     </table>
               </CCol>
             </CRow>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
  
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils/src'

function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export default {
  name: 'ChartMensajes',
  components: {
    CChartLine
  },
  data () {
    return {
      items: [],
      fields: [
        { key: 'dia',label:'Dia'},
        { key: 'mensajes',label:'Mensajes Enviados',},
        { key: 'credito',label:'Creditos Consumidos'},
      ],
      activePage: 1,
    }
  },
  props:{
    dataChartMensajes: Object,
    dataConsultaChart: Object,
  },
  methods: {
    creditosConversorMoneda(value){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      return (formatterDolar.format(value)).replace('$','');
    },
  },
  computed: {
    totalCelulares(){
      return this.dataChartMensajes['enviados'] != undefined ? this.dataChartMensajes['enviados'].reduce((a, b) => parseInt(a) + parseInt(b), 0) : '' ;
    },
    totalMensajes(){
      return this.dataChartMensajes['total'] != undefined ? this.dataChartMensajes['total'].reduce((a, b) => parseInt(a) + parseInt(b), 0) : '' ;
    },
    defaultDatasets () {
      const brandSuccess = getStyle('success2') || '#4dbd74'
      const brandInfo = getStyle('info') || '#20a8d8'
      const brandDanger = getStyle('danger') || '#f86c6b'

      /*let elements = 27
      const data1 = []
      const data2 = []

      for (let i = 0; i <= elements; i++) {
        data1.push(random(50, 200))
        data2.push(random(80, 100))
      }*/
      try {
        let array = this.dataChartMensajes['dias']
        for (let index = 0; index < array.length; index++) {
          this.items.push({dia:this.dataChartMensajes['fecha'][index],mensajes:this.dataChartMensajes['enviados'][index],credito:this.dataChartMensajes['total'][index]});
          
        }
      } catch (error) {
        
      }
      
      const data1 = this.dataChartMensajes['enviados'];
      const data2 = this.dataChartMensajes['total'];
      return [
        {
          label: 'Cantidad de Mensajes Enviados',
          backgroundColor: hexToRgba(brandInfo, 10),
          borderColor: brandInfo,
          pointHoverBackgroundColor: brandInfo,
          borderWidth: 2,
          data: data1
        },
        {
          label: 'Ceditos',
          backgroundColor: 'transparent',
          borderColor: brandSuccess,
          pointHoverBackgroundColor: brandSuccess,
          borderWidth: 2,
          data: data2
        },
      ]
    },
    defaultOptions () {
      let maxTicksLimit = this.dataChartMensajes['maxTicksLimit'] == undefined ? 10 : this.dataChartMensajes['maxTicksLimit'];
      let maximo_grafica = this.dataChartMensajes['maximo_grafica'] == undefined ? 500 : parseInt(this.dataChartMensajes['maximo_grafica']);
      maximo_grafica = maximo_grafica + (maximo_grafica/2);
      let stepSize = this.dataChartMensajes['stepSize'] == undefined ? Math.ceil(500 / 10) : Math.ceil(maximo_grafica/maxTicksLimit);
      return {

        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            }
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: maxTicksLimit,//10,
              stepSize: stepSize,//Math.ceil(500 / 10),
              max: maximo_grafica,//500
            },
            gridLines: {
              display: true
            }
          }]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3
          }
        }
      }
    }
  }
}
</script>

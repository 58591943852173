<template>
 <CRow>
      <CCol col="12">
        <CCard>
          <CCardBody>
             <CRow>
               <CCol col="12">
                 <CRow>
                      <CCol col="12">
                        <h4 class="card-title mb-0 text-center">Cantidad Mensajes Enviados Por Semanas</h4>
                        <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
                      </CCol>
                    </CRow>
                      <div id="chart">
                        <apexchart type="line" height="350" :options="defaultOptions" :series="defaultDatasets"></apexchart>
                      </div>
               </CCol>
             </CRow>
             <br>
             <CRow>
               <CCol col="12">
                   <h4 class="card-title mb-0 text-center">Detalle Cantidad Mensajes Enviados Por Semanas</h4>
                    <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
               </CCol>
               <CCol col="12">
                      <table class="table">
                        <tr>
                          <th>Campañas</th>
                          <th>Mensajes Enviados</th>
                          <th>Creditos Consumidos</th>
                        </tr>
                        <tr v-for="(item, index) in items">
                          <td>{{item['semana']}}</td>
                          <td>{{creditosConversorMoneda(item['mensajes'])}}</td>
                          <td>{{creditosConversorMoneda(item['credito'])}}</td>
                        </tr>
                        <tr>
                           <td></td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalCelulares)}}</td> </td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalMensajes)}}</td>
                        </tr>
                     </table>
               </CCol>
             </CRow>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
  
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  name: 'ChartMensajesSemanal',
  components: {
    apexchart: VueApexCharts,
  },
  data () {
    return {
      items: [],
      fields: [
        { key: 'dia',label:'Dia'},
        { key: 'mensajes',label:'Mensajes Enviados',},
        { key: 'credito',label:'Creditos Consumidos'},
      ],
      activePage: 1,
    }
  },
  props:{
    dataChartMensajesSemanal: Object,
    dataConsultaChart: Object,
  },
  methods: {
    creditosConversorMoneda(value){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      return (formatterDolar.format(value)).replace('$','');
    },
  },
  computed: {
    totalCelulares(){
      return this.dataChartMensajesSemanal['enviados'] != undefined ? this.dataChartMensajesSemanal['enviados'].reduce((a, b) => parseInt(a) + parseInt(b), 0) : '' ;
    },
    totalMensajes(){
      return this.dataChartMensajesSemanal['total'] != undefined ? this.dataChartMensajesSemanal['total'].reduce((a, b) => parseInt(a) + parseInt(b), 0) : '' ;
    },
    defaultDatasets () {
      try {
        let array = this.dataChartMensajesSemanal['semana']
        for (let index = 0; index < array.length; index++) {
          this.items.push({semana:this.dataChartMensajesSemanal['semana'][index],mensajes:this.dataChartMensajesSemanal['enviados'][index],credito:this.dataChartMensajesSemanal['total'][index]});
        }
      } catch (error) {
        
      }
      
      const data1 = this.dataChartMensajesSemanal['enviados'] != undefined ? this.dataChartMensajesSemanal['enviados'] : [];
      const data2 = this.dataChartMensajesSemanal['total']  != undefined ? this.dataChartMensajesSemanal['total'] : [];
      return [{
            name: 'Mensajes Enviados',
            type: 'column',
            data: data1,
          }, {
            name: 'Creditos',
            type: 'line',
            data: data2,
          }]
    },
    defaultOptions () {
      //let maxTicksLimit = this.dataChartMensajesSemanal['maxTicksLimit'] == undefined ? 10 : this.dataChartMensajesSemanal['maxTicksLimit'];
      //let maximo_grafica = this.dataChartMensajesSemanal['maximo_grafica'] == undefined ? 500 : parseInt(this.dataChartMensajesSemanal['maximo_grafica']);
      //maximo_grafica = maximo_grafica + (maximo_grafica/2);
      //let stepSize = this.dataChartMensajesSemanal['stepSize'] == undefined ? Math.ceil(500 / 10) : Math.ceil(maximo_grafica/maxTicksLimit);
      let semanas = this.dataChartMensajesSemanal['semana'] != undefined ? this.dataChartMensajesSemanal['semana'] : ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'];
        return {
            chart: {
              height: 350,
              type: 'line',
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false,
              }
            },
            stroke: {
              width: [0, 4]
            },
            title: {
              text: 'Mensajes Enviados Por Semana'
            },
            dataLabels: {
              enabled: true,
              enabledOnSeries: [1]
            },
            labels: semanas,
            xaxis: {
              type: 'category'
            },
            yaxis: [{
              title: {
                text: 'Mensajes Enviados',
              },
            
            }, {
              opposite: true,
              title: {
                text: 'Creditos'
              }
            }]
          }
    }
  }
}
</script>

<template>
 <CRow>
      <CCol col="12">
        <CCard>
          <CCardBody>
             <CRow>
               <CCol col="12">
                 <CRow>
                      <CCol col="12">
                        <h4 class="card-title mb-0 text-center">Cantidad Mensajes Enviados Por Cuentas y SubCuentas</h4>
                        <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
                      </CCol>
                    </CRow>
                      <div id="chart">
                        <apexchart type="bar" height="350" :options="defaultOptions" :series="defaultDatasets"></apexchart>
                      </div>
               </CCol>
             </CRow>
             <br>
             <CRow>
               <CCol col="12">
                   <h4 class="card-title mb-0 text-center">Detalle Cantidad Mensajes Enviados Por Cuentas y SubCuentas</h4>
                    <div class="small text-muted text-center">{{dataConsultaChart.fechainicio}} - {{dataConsultaChart.fechafin}}</div>
               </CCol>
               <CCol col="12">
                      <table class="table">
                        <tr>
                          <th>Cuentas</th>
                          <th>Mensajes Enviados</th>
                          <th>Creditos Consumidos</th>
                        </tr>
                        <tr v-for="(item, index) in items">
                          <td>{{item['user']}}</td>
                          <td>{{creditosConversorMoneda(item['mensajes'])}}</td>
                          <td>{{creditosConversorMoneda(item['credito'])}}</td>
                        </tr>
                        <tr>
                           <td></td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalCelulares)}}</td> </td>
                           <td><strong>Total:</strong> {{creditosConversorMoneda(totalMensajes)}}</td>
                        </tr>
                     </table>
               </CCol>
             </CRow>
          </CCardBody>
        </CCard>
      </CCol>
   </CRow>
  
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  name: 'ChartMensajesSubcuenta',
  components: {
    apexchart: VueApexCharts,
  },
  data () {
    return {
      items: [],
    }
  },
  props:{
    dataChartMensajesSubcuenta: Object,
    dataConsultaChart: Object,
  },
  methods: {
    creditosConversorMoneda(value){
      const formatterDolar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
      })
      return (formatterDolar.format(value)).replace('$','');
    },
  },
  computed: {
    totalCelulares(){
      return this.dataChartMensajesSubcuenta['enviados'] != undefined ? this.dataChartMensajesSubcuenta['enviados'].reduce((a, b) => parseInt(a) + parseInt(b), 0) : '' ;
    },
    totalMensajes(){
      return this.dataChartMensajesSubcuenta['total'] != undefined ? this.dataChartMensajesSubcuenta['total'].reduce((a, b) => parseInt(a) + parseInt(b), 0) : '' ;
    },
    defaultDatasets () {
      try {
        let array = this.dataChartMensajesSubcuenta['user']
        for (let index = 0; index < array.length; index++) {
          this.items.push({user:this.dataChartMensajesSubcuenta['user'][index],mensajes:this.dataChartMensajesSubcuenta['enviados'][index],credito:this.dataChartMensajesSubcuenta['total'][index]});
        }
      } catch (error) {
        
      }
      
      const data1 = this.dataChartMensajesSubcuenta['enviados'] != undefined ? this.dataChartMensajesSubcuenta['enviados'] : [];
      const data2 = this.dataChartMensajesSubcuenta['total']  != undefined ? this.dataChartMensajesSubcuenta['total'] : [];
      return [{
            name: 'Mensajes Enviados',
            //type: 'column',
            data: data1,
          }, {
            name: 'Creditos',
            //type: 'line',
            data: data2,
          }]
    },
    defaultOptions () {
      //let maxTicksLimit = this.dataChartMensajesSubcuenta['maxTicksLimit'] == undefined ? 10 : this.dataChartMensajesSubcuenta['maxTicksLimit'];
      //let maximo_grafica = this.dataChartMensajesSubcuenta['maximo_grafica'] == undefined ? 500 : parseInt(this.dataChartMensajesSubcuenta['maximo_grafica']);
      //maximo_grafica = maximo_grafica + (maximo_grafica/2);
      //let stepSize = this.dataChartMensajesSubcuenta['stepSize'] == undefined ? Math.ceil(500 / 10) : Math.ceil(maximo_grafica/maxTicksLimit);
      let users = this.dataChartMensajesSubcuenta['user'] != undefined ? this.dataChartMensajesSubcuenta['user'] : ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'];
        return {
            chart: {
              type: 'bar',
              height: '100%',
              stacked: true,
              toolbar: {
                show: false
              },
              zoom: {
                enabled: false
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10
              },
            },
            xaxis: {
              type: 'category',
              categories: users,
            },
            legend: {
              position: 'right',
              offsetY: 40
            },
            fill: {
              opacity: 1
            }
          };
    }
  }
}
</script>
